import React from "react";
import { Navigation } from "../components/navigation";

const PrivacyPage = () => {
  return (
    <div
      style={{ display: "flex", justifyContent: "center", paddingTop: "100px" }}
    >
      <div
        style={{ maxWidth: "800px", padding: "0 20px", textAlign: "center" }}
      >
        <h1>Privacy Policy</h1>
        <p style={{ textAlign: "justify" }}>
          Welcome to Shellback Studios. Your privacy and the security of your
          personal information are of paramount importance to us. This Privacy
          Policy outlines our practices regarding the collection, use, and
          sharing of information through our digital properties. By accessing or
          using our services, you signify your understanding of and consent to
          the policies and practices described herein.
        </p>

        <h2>Information We Collect</h2>
        <p style={{ textAlign: "justify" }}>
          Shellback Studios is dedicated to minimal data collection practices to
          ensure your privacy is respected. We leverage Unity Ad Manager and
          Google Analytics to enhance our services and your user experience
          without compromising your personal information. Specifically, we
          collect data related to your interactions with our services, such as
          usage patterns and preferences, through these platforms. Importantly,
          we do not collect sensitive personal information or data that directly
          identifies you unless explicitly provided by you.
        </p>

        <h2>Use of Collected Information</h2>
        <p style={{ textAlign: "justify" }}>
          The information we collect through Unity Ad Manager and Google
          Analytics is used to understand how our services are being used,
          identify areas for improvement, and optimize the user experience. It
          also aids in delivering targeted advertisements that are relevant to
          your interests. We want to reassure you that we do not, under any
          circumstances, sell or rent your information to third parties. Our use
          of your information is strictly limited to the purposes of improving
          service delivery and enhancing user satisfaction.
        </p>

        <h2>Third-Party Services</h2>
        <p style={{ textAlign: "justify" }}>
          In our use of Unity Ad Manager and Google Analytics, it is important
          to note that these third-party services operate under their own
          privacy policies. We encourage you to review these policies to
          understand how they collect and use your information. While we strive
          to protect your personal information, our Privacy Policy does not
          cover the information practices of third parties.
        </p>

        <h2>Data Security</h2>
        <p style={{ textAlign: "justify" }}>
          Protecting your personal information is a responsibility we take
          seriously. We implement a variety of security measures designed to
          maintain the safety of your personal information from unauthorized
          access, use, alteration, or destruction. These measures include, but
          are not limited to, encryption, access controls, and secure data
          storage. Despite these efforts, please be aware that no security
          measures are impenetrable, and we cannot guarantee the absolute
          security of your information.
        </p>

        <h2>Your Rights and Choices</h2>
        <p style={{ textAlign: "justify" }}>
          You have certain rights regarding the personal information we hold
          about you. These may include the right to access, correct, delete, or
          restrict the use of your information, as well as the right to object
          to certain processing activities. If you wish to exercise any of these
          rights, please contact us at the details provided below.
        </p>

        <h2>Contact Us</h2>
        <p style={{ textAlign: "justify" }}>
          If you have questions, comments, or concerns about our Privacy Policy
          or practices, please do not hesitate to contact us at
          support@shellback-studios.com. We are committed to addressing your
          privacy-related inquiries and resolving any issues in a timely and
          respectful manner.
        </p>
        {/* Div with vertical padding */}
        <div style={{ padding: "50px 0" }}></div>
      </div>
    </div>
  );
};

export default PrivacyPage;
