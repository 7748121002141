import React, { useEffect } from "react";
import { useLocation, useNavigate, Link } from "react-router-dom";

export const Navigation = (props) => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const allowedPaths = [
      "/",
      "/privacy",
      "/about",
      "/services",
      "/team",
      "/contact",
      "/features",
    ];
    if (!allowedPaths.includes(location.pathname)) {
      navigate("/");
    }
  }, [location, navigate]);

  return (
    <nav id="menu" className="navbar navbar-default navbar-fixed-top">
      <div className="container">
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
          >
            {" "}
            <span className="sr-only">Toggle navigation</span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
          </button>
          <a
            className="navbar-brand page-scroll"
            href={location.pathname === "/" ? "#page-top" : "/"}
          >
            Shellback Studios{" "}
          </a>{" "}
        </div>

        <div
          className="collapse navbar-collapse"
          id="bs-example-navbar-collapse-1"
        >
          <ul className="nav navbar-nav navbar-right">
            <li>
              <a
                href={location.pathname === "/" ? "#about" : "/"}
                className="page-scroll"
              >
                About
              </a>
            </li>
            <li>
              <a
                href={location.pathname === "/" ? "#services" : "/"}
                className="page-scroll"
              >
                Services
              </a>
            </li>
            <li>
              <a
                href={location.pathname === "/" ? "#team" : "/"}
                className="page-scroll"
              >
                Team
              </a>
            </li>
            <li>
              <a
                href={location.pathname === "/" ? "#contact" : "/"}
                className="page-scroll"
              >
                Contact
              </a>
            </li>
            {/* <li>
              <a
                href={location.pathname === "/" ? "#features" : "/"}
                className="page-scroll"
              >
                Features
              </a>
            </li> */}
            <li>
              <Link
                to={location.pathname === "/" ? "/privacy" : "/"}
                className="page-scroll"
              >
                Privacy
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};
